import ClassicSectionComponent from '@wix/thunderbolt-elements/src/components/ClassicSection/viewer/ClassicSection';


const ClassicSection = {
  component: ClassicSectionComponent
};


export const components = {
  ['ClassicSection']: ClassicSection
};


// temporary export
export const version = "1.0.0"
